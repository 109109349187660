import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { state } from '../states/store/initialState'
import { User } from '../models'

function PrivateRoute(props: any) {
  const isEmpty: string = useSelector(
    (state: state) => state.firebase.auth.isEmpty,
  )
  const user: User = useSelector((state: state) => state.user)
  const re = new RegExp(/^\/app\/welcome*/)

  if (isEmpty) {
    return <Redirect to="/login" />
  } else if (user.id && !user.defaultCorporationId) {
    if (!re.test(props.location.pathname)) {
      props.location.pathname = '/app/welcome'
    }
    return <Route {...props} />
  } else if (re.test(props.location.pathname)) {
    props.location.pathname = '/app/dashboard'
    return <Route {...props} />
  } else return <Route {...props} />
}

export default PrivateRoute
