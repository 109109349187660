/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { dummyUser } from '../../constants/dummy'
import { User } from '../../models/index'

export const CREATE_USER = 'CREATE_USER'
export const GET_USER = 'GET_USER'
export const RESET_USER = 'RESET_USER'
export const GET_USERS = 'GET_USERS'

export const createUser = (user: User) => {
  return {
    type: CREATE_USER,
    payload: { user },
  }
}

export const getUser = (response: any) => {
  return {
    type: GET_USER,
    payload: {
      ...response,
    },
  }
}

export const resetUser = () => {
  return {
    type: RESET_USER,
    payload: dummyUser,
  }
}

export const getUsers = (response: any) => {
  return {
    type: GET_USERS,
    payload: {
      ...response,
    },
  }
}
