/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { dummyStresscheck } from '../../constants/dummy'
import { Stresscheck } from '../../models/index'

export const CREATE_STRESSCHECK = 'CREATE_STRESSCHECK'
export const SET_STRESSCHECK = 'SET_STRESSCHECK'
export const GET_STRESSCHECKS = 'GET_STRESSCHECKS'
export const RESET_STRESSCHECK = 'RESET_STRESSCHECK'
export const RESET_STRESSCHECKS = 'RESET_STRESSCHECKS'

//TODO DELETE?
export const createStresscheck = (stresscheck: Stresscheck) => {
  return {
    type: CREATE_STRESSCHECK,
    payload: {
      id: 1,
    },
  }
}

export const setStresscheck = (stresscheck: Partial<Stresscheck>) => {
  return {
    type: SET_STRESSCHECK,
    payload: stresscheck,
  }
}

export const resetStresscheck = () => {
  return {
    type: RESET_STRESSCHECK,
    payload: dummyStresscheck,
  }
}

export const getStresschecks = (response: any) => {
  return {
    type: GET_STRESSCHECKS,
    payload: {
      ...response,
    },
  }
}

export const resetStresschecks = () => {
  return {
    type: RESET_STRESSCHECKS,
    payload: {
      ...[],
    },
  }
}
