import i18n from 'i18next'
import jaTranslation from './ja/translation.json'
import enTranslation from './en/translation.json'
import viTranslation from './vi/translation.json'
import idTranslation from './id/translation.json'
import { initReactI18next } from 'react-i18next'

const resources = {
  ja: {
    translation: jaTranslation,
  },
  en: {
    translation: enTranslation,
  },
  vi: {
    translation: viTranslation,
  },
  id: {
    translation: idTranslation,
  },
}

i18n.use(initReactI18next).init({
  lng: 'ja',
  fallbackLng: 'en',
  debug: true,

  interpolation: {
    escapeValue: false,
  },

  react: {
    wait: true,
  },
  resources: resources,
})

export default i18n
