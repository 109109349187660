/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Actions from './actions'
import initialState from '../store/initialState'
import { action } from './types'

export function BusinessTypeMasterReducer(
  state = initialState.businessTypeMaster,
  action: action,
) {
  switch (action.type) {
    case Actions.SET_BUSINESS_TYPE_MASTER:
      return {
        ...state,
        ...action.payload,
      }
    case Actions.RESET_BUSINESS_TYPE_MASTER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
