/* eslint-disable @typescript-eslint/no-explicit-any */
import { dummyYearlySummary } from '../../constants/dummy'
import { YearlySummary } from '../../models'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const RESET_YEARLY_SUMMARY = 'RESET_YEARLY_SUMMARY'
export const SET_YEARLY_SUMMARY = 'SET_YEARLY_SUMMARY'

export const setYearlySummary = (YearlySummary: Partial<YearlySummary>) => {
  return {
    type: SET_YEARLY_SUMMARY,
    payload: YearlySummary,
  }
}

export const resetYearlySummary = () => {
  return {
    type: RESET_YEARLY_SUMMARY,
    payload: dummyYearlySummary,
  }
}
