/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { dummyCorporation } from '../../constants/dummy'
import { Corporation } from '../../models/index'

export const CREATE_CORPORATION = 'CREATE_CORPORATION'
export const RESET_CORPORATION = 'RESET_CORPORATION'
export const SET_CORPORATION = 'SET_CORPORATION'

export const createCorporation = (corporation: Corporation) => {
  return {
    type: CREATE_CORPORATION,
    payload: corporation,
  }
}

export const setCorporation = (corporation: Partial<Corporation>) => {
  return {
    type: SET_CORPORATION,
    payload: corporation,
  }
}

export const resetCorporation = () => {
  return {
    type: RESET_CORPORATION,
    payload: dummyCorporation,
  }
}
