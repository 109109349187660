/* eslint-disable @typescript-eslint/no-explicit-any */
import { dummyBusinessTypeMaster } from '../../constants/dummy'
import { BusinessTypeMaster } from '../../models'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const RESET_BUSINESS_TYPE_MASTER = 'RESET_BUSINESS_TYPE_MASTER'
export const SET_BUSINESS_TYPE_MASTER = 'SET_BUSINESS_TYPE_MASTER'

export const setBusinessTypeMaster = (
  businessTypeName: Partial<BusinessTypeMaster>,
) => {
  return {
    type: SET_BUSINESS_TYPE_MASTER,
    payload: businessTypeName,
  }
}

export const resetBusinessTypeMaster = () => {
  return {
    type: RESET_BUSINESS_TYPE_MASTER,
    payload: dummyBusinessTypeMaster,
  }
}
