/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import initialState, { state } from '../store/initialState'

type args = { type?: string; [key: string]: any }

function coreuiReducer(
  state: state['coreui'] = initialState['coreui'],
  { type, ...rest }: args,
) {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

export default coreuiReducer
