/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const SET_ANALYSIS = 'SET_ANALYSIS'
export const RESET_ANALYSIS = 'RESET_ANALYSIS'

export const setAnalysis = (ANALYSIS: any) => {
  return {
    type: SET_ANALYSIS,
    payload: {
      ...ANALYSIS,
    },
  }
}

export const resetAnalysis = () => {
  return {
    type: RESET_ANALYSIS,
    payload: {
      ...[],
    },
  }
}
