/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Actions from './actions'
import initialState from '../store/initialState'
import { action } from './types'
import { StresscheckDetail } from '../../models'

export function StresscheckDetailsReducer(
  state = initialState.stresscheckDetails,
  action: action,
) {
  switch (action.type) {
    case Actions.GET_STRESSCHECKDETAILS:
      return {
        ...(action.payload as StresscheckDetail[]),
      }
    case Actions.RESET_STRESSCHECKDETAILS:
      return {
        ...(action.payload as StresscheckDetail[]),
      }
    default:
      return {
        ...state,
      }
  }
}
