/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const GET_STRESSCHECKDETAILS = 'GET_STRESSCHECKDETAILS'
export const RESET_STRESSCHECKDETAILS = 'RESET_STRESSCHECKDETAILS'

export const getStresscheckDetails = (response: any) => {
  return {
    type: GET_STRESSCHECKDETAILS,
    payload: {
      ...response,
    },
  }
}

export const resetStresscheckDetails = () => {
  return {
    type: RESET_STRESSCHECKDETAILS,
    payload: {
      ...[],
    },
  }
}
