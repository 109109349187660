/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Actions from './actions'
import initialState from '../store/initialState'
import { action } from './types'
import { Employee } from '../../models'

export function EmployeesReducer(
  state = initialState.employees,
  action: action,
) {
  switch (action.type) {
    case Actions.CREATE_EMPLOYEE:
      return {
        ...state,
        //list: action.payload,
      }
    case Actions.GET_EMPLOYEES:
      return {
        ...(action.payload as Employee[]),
      }
    case Actions.RESET_EMPLOYEES:
      return {
        ...(action.payload as Employee[]),
      }
    default:
      return {
        ...state,
      }
  }
}
