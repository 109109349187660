import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { AuthReducer } from '../auth/reducers'
import coreuiReducer from '../coreui/reducers'
import { UserReducer, UsersReducer } from '../users/reducers'
import { CorporationsReducer } from '../corporations'
import { EmployeesReducer } from '../employees'
import { StresscheckReducer, StresschecksReducer } from '../stresschecks'
import {
  firebaseReducer,
  getFirebase,
  reactReduxFirebase,
} from 'react-redux-firebase'
import initialState from './initialState'
import { ResponsesReducer } from '../responses'
import { BusinessTypeMasterReducer } from '../businessTypeMaster'
import { YearlySummaryReducer } from '../yearlySummaries'
import { StresscheckDetailsReducer } from '../stresscheckDetails'
import { AnalysisReducer } from '../analysis'
import {
  UserCorporationRelationReducer,
  UserCorporationRelationsReducer,
} from '../userCorporationRelations'

const middlewares = [thunk.withExtraArgument(getFirebase)]
const composeEnhancer = composeWithDevTools(
  applyMiddleware(...middlewares),
  //reactReduxFirebase(firebaseConfig, { attachAuthIsReady: true }),
)

export default function createStore() {
  return reduxCreateStore(
    combineReducers({
      auth: AuthReducer,
      coreui: coreuiReducer,
      user: UserReducer,
      users: UsersReducer,
      businessTypeMaster: BusinessTypeMasterReducer,
      corporation: CorporationsReducer,
      yearlySummary: YearlySummaryReducer,
      employees: EmployeesReducer,
      stresscheck: StresscheckReducer,
      stresschecks: StresschecksReducer,
      stresscheckDetails: StresscheckDetailsReducer,
      userCorporationRelation: UserCorporationRelationReducer,
      userCorporationRelations: UserCorporationRelationsReducer,
      firebase: firebaseReducer,
      analysis: AnalysisReducer,
      response: ResponsesReducer,
    }),
    initialState,
    composeEnhancer,
  )
}
