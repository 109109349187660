/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Actions from './actions'
import initialState from '../store/initialState'
import { action } from './types'
import { User } from '../../models'

export function UserReducer(state = initialState.user, action: action) {
  switch (action.type) {
    case Actions.CREATE_USER:
      return {
        ...state,
      }
    case Actions.GET_USER:
      return {
        ...state,
        ...action.payload,
      }
    case Actions.RESET_USER:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export function UsersReducer(state = initialState.users, action: action) {
  switch (action.type) {
    case Actions.GET_USERS:
      return {
        ...(action.payload as User[]),
      }
    default:
      return {
        ...state,
      }
  }
}
