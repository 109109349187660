/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { dummyUserCorporationRelation } from '../../constants/dummy'
import { UserCorporationRelation } from '../../models/index'

export const CREATE_USERCORPORATIONRELATION = 'CREATE_USERCORPORATIONRELATION'
export const GET_USERCORPORATIONRELATION = 'GET_USERCORPORATIONRELATION'
export const RESET_USERCORPORATIONRELATION = 'RESET_USERCORPORATIONRELATION'
export const GET_USERCORPORATIONRELATIONS = 'GET_USERCORPORATIONRELATIONS'

export const createUserCorporationRelation = (
  userCorporationRelation: UserCorporationRelation,
) => {
  return {
    type: CREATE_USERCORPORATIONRELATION,
    payload: { userCorporationRelation },
  }
}

export const getUserCorporationRelation = (response: any) => {
  return {
    type: GET_USERCORPORATIONRELATION,
    payload: {
      ...response,
    },
  }
}

export const resetUserCorporationRelation = () => {
  return {
    type: RESET_USERCORPORATIONRELATION,
    payload: dummyUserCorporationRelation,
  }
}

export const getUserCorporationRelations = (response: any) => {
  return {
    type: GET_USERCORPORATIONRELATIONS,
    payload: {
      ...response,
    },
  }
}
