/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  }
}

export const loginError = () => {
  return {
    type: LOGIN_ERROR,
  }
}

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
}

export const signUpSuccess = () => {
  return {
    type: SIGNUP_SUCCESS,
  }
}

export const signUpError = (err: any) => {
  return {
    type: SIGNUP_ERROR,
    payload: { err },
  }
}
