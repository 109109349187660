/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Actions from './actions'
import initialState from '../store/initialState'
import { action } from './types'

export function YearlySummaryReducer(
  state = initialState.yearlySummary,
  action: action,
) {
  switch (action.type) {
    case Actions.SET_YEARLY_SUMMARY:
      return {
        ...state,
        ...action.payload,
      }
    case Actions.RESET_YEARLY_SUMMARY:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
