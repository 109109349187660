/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const SET_RESPONSE = 'SET_RESPONSE'
export const RESET_RESPONSE = 'RESET_RESPONSE'

export const setResponse = (response: any) => {
  return {
    type: SET_RESPONSE,
    payload: {
      ...response,
    },
  }
}

export const resetResponse = () => {
  return {
    type: RESET_RESPONSE,
    payload: {
      ...[],
    },
  }
}
