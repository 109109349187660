import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import firebase from 'firebase/compat/app'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import './locales/i18n'
import createStore from './states/store/store'
import { firebaseConfig } from './firebase'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

const store = createStore()

// react-redux-firebase config
firebase.initializeApp(firebaseConfig)
const rrfConfig = {
  userProfile: 'users',
}
const rrfProps = {
  firebase,
  config: {}, //rrfConfig,
  dispatch: store.dispatch,
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
