import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import PrivateRoute from './routes/privateRoute'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const AppLayout = React.lazy(() => import('./layout/AppLayout'))

class App extends Component {
  componentDidMount(): void {
    document.title = 'Mental Map'
  }
  render(): JSX.Element {
    return (
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Switch>
            <PrivateRoute path="/app" render={() => <AppLayout />} />
            <Route path="/" render={() => <DefaultLayout />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
