import {
  Corporation,
  Employee,
  Stresscheck,
  User,
  UserCorporationRelation,
  YearlySummary,
} from '../models'
import { BusinessType } from '../models/businessType'
import { BusinessTypeMaster } from '../models/businessTypeMaster'

export const dummyEmployee: Employee = {
  id: 0,
  employeeCode: '',
  lastNameKana: '',
  firstNameKana: '',
  lastName: '',
  firstName: '',
  dateOfBirth: new Date(2000, 1, 1),
  dateHired: new Date(2000, 1, 1),
  division: '',
  sex: 'M',
  email: '',
  title: '',
  isManager: false,
}

export const dummyUser: User = {
  id: '',
  email: '',
  phoneNumber: '',
  firebaseAuthUid: '',
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  role: '',
  title: '',
  corporations: [],
  defaultCorporationId: '',
  currentCorporationId: '',
}

export const dummyBusinessType: BusinessType = {
  id: -1,
  l1Code: '',
  l1Name: '',
  l2Code: '',
  l2Name: '',
  l3Code: '',
  l3Name: '',
  l4Code: '',
  l4Name: '',
}

export const dummyBusinessTypeMaster: BusinessTypeMaster = {
  l1Name: '',
  l2Name: '',
  l3Name: '',
  l4Name: '',
  l1Names: [],
  l2Names: [],
  l3Names: [],
  l4Names: [],
}

export const dummyCorporation: Corporation = {
  id: '',
  corporationNumber: '',
  name: '',
  nameKana: '',
  balance: 0,
  postalCode: '',
  addressPrefecture: '',
  addressCity: '',
  addressStreet: '',
  businessType: dummyBusinessType,
  divisionSet: [],
  division1Name: '',
  division2Name: '',
  division3Name: '',
}

const today = new Date()

export const dummyStresscheck: Stresscheck = {
  id: '',
  corporationId: '',
  fiscalYear: today.getFullYear(),
  name: '',
  dateStarted: today,
  dateEnded: today,
  memo: '',
  count: 0,
  total: 0,
  type: '',
  user: '',
  corporation: '',
  yearlySummary: '',
}

export const dummyYearlySummary: YearlySummary = {
  id: '',
  corporation: dummyCorporation,
  fiscalYear: today.getFullYear(),
  status: 'NotYet',
  statusEmployeesMaster: 'NotYet',
  statusDivisions: 'NotYet',
  statusStresschecks: 'NotYet',
  statusLaborBureauDuty: 'NotYet',
  statusEmployeeMasterUpdatedAt: undefined,
  statusDivisionsUpdatedAt: undefined,
  statusStresschecksUpdatedAt: undefined,
  statusLaborBureauDutyUpdatedAt: undefined,
  divisions: [],
}

export const dummyUserCorporationRelation: UserCorporationRelation = {
  id: '',
  user: dummyUser,
  receiver: dummyUser,
  receiverName: '',
  receiverEmail: '',
  corporation: dummyCorporation,
  role: '',
  status: '',
  isDisabled: false,
  createdAt: today,
  repliedAt: today,
}
