/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Employee } from '../../models/index'

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const RESET_EMPLOYEES = 'RESET_EMPLOYEES'

export const createEmployee = (employee: Employee) => {
  return {
    type: CREATE_EMPLOYEE,
    payload: { employee },
  }
}

export const getEmployees = (response: any) => {
  return {
    type: GET_EMPLOYEES,
    payload: {
      ...response,
    },
  }
}

export const resetEmployees = () => {
  return {
    type: RESET_EMPLOYEES,
    payload: {
      ...[],
    },
  }
}
