/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Actions from './actions'
import initialState from '../store/initialState'
import { action } from './types'

export function AnalysisReducer(state = initialState.analysis, action: action) {
  switch (action.type) {
    case Actions.SET_ANALYSIS:
      return {
        ...action.payload,
      }
    case Actions.RESET_ANALYSIS:
      return {
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
