import { dummyUser } from '../../constants/dummy'
import * as Actions from './actions'
import { action } from './types'

const initState = {
  authError: '',
}

export function AuthReducer(state = initState, action: action) {
  switch (action.type) {
    case Actions.LOGIN_ERROR:
      return {
        ...state,
        authError: 'LoginFailed',
      }
    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        authError: null,
      }
    case Actions.SIGNOUT_SUCCESS:
      return state
    case Actions.SIGNUP_SUCCESS:
      return {
        ...state,
        authError: null,
      }
    case Actions.SIGNUP_ERROR:
      let err = 'UnknownError'
      if (action.payload.err.message.includes('internal-error'))
        err = 'InternalError'
      else if (action.payload.err.message.includes('email-already-in-use'))
        err = 'EmailAlreadyInUse'
      action.payload.err.message = err
      return {
        ...state,
        authError: action.payload.err.message,
      }
    default:
      return state
  }
}
